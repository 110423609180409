<template>
  <div>
    <b-card>
      <b-card-header style="padding-top: 0px; padding-right: 0px; padding-left: 0px">
        <div class="d-flex align-items-center">
          <b-card-title>
            <h4><b style="color: cornflowerblue">Tambah Soal</b></h4>
          </b-card-title>
        </div>
        <b-nav>
          <b-nav-item>
            <router-link to="/admin/question">
              <b-button variant="primary">
                <feather-icon icon="ArrowLeftIcon" style="color: white" />
                <span class="align-right"><b style="color: white"> Kembali</b></span>
              </b-button>
            </router-link>
          </b-nav-item>
        </b-nav>
      </b-card-header>
      <b-form method="post" enctype="multipart/form-data" @submit.prevent="addData()">
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default">Nama Kategori</label>
            <v-select name="register-kampus" placeholder="Cari Nama Kategori" v-model="id_category" :value="id"
              :reduce="(nama) => nama.id" :options="listCategories" label="nama">
            </v-select>
          </b-col>
        </b-row>
       
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default">Tinjauan 1</label>
            <v-select name="register-kampus" placeholder="Cari Tinjauan 1" v-model="tinjauansatuses_id" :value="id"
              :reduce="(nama) => nama.id" :options="listTinjauan1" label="nama">
            </v-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default">Tinjauan 2</label>
            <v-select name="register-kampus" placeholder="Cari Tinjauan 2" v-model="tinjauanduas_id" :value="id"
              :reduce="(nama) => nama.id" :options="listTinjauan2" label="nama">
            </v-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default">Tinjauan 3</label>
            <v-select name="register-kampus" placeholder="Cari Tinjauan 3" v-model="tinjauantigas_id" :value="id"
              :reduce="(nama) => nama.id" :options="listTinjauan3" label="nama">
            </v-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default">Tinjauan 4</label>
            <v-select name="register-kampus" placeholder="Cari Tinjauan 4" v-model="tinjauanempats_id" :value="id"
              :reduce="(nama) => nama.id" :options="listTinjauan4" label="nama">
            </v-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default">Tinjauan 5</label>
            <v-select name="register-kampus" placeholder="Cari Tinjauan 5" v-model="tinjauanlimas_id" :value="id"
              :reduce="(nama) => nama.id" :options="listTinjauan5" label="nama">
            </v-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default">Tinjauan 6</label>
            <v-select name="register-kampus" placeholder="Cari Tinjauan 6" v-model="tinjauanenams_id" :value="id"
              :reduce="(nama) => nama.id" :options="listTinjauan6" label="nama">
            </v-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default">Tinjauan 7</label>
            <v-select name="register-kampus" placeholder="Cari Tinjauan 7" v-model="tinjauantujuhs_id" :value="id"
              :reduce="(nama) => nama.id" :options="listTinjauan7" label="nama">
            </v-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default">Tinjauan 8</label>
            <v-select name="register-kampus" placeholder="Cari Tinjauan 8" v-model="tinjauandelapans_id" :value="id"
              :reduce="(nama) => nama.id" :options="listTinjauan8" label="nama">
            </v-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default">Tinjauan 9</label>
            <v-select name="register-kampus" placeholder="Cari Tinjauan 9" v-model="tinjauansembilans_id" :value="id"
              :reduce="(nama) => nama.id" :options="listTinjauan9" label="nama">
            </v-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default">Isi Soal</label>
            <b-form-textarea id="textarea-default" placeholder="Isi Soal" v-model="question" rows="3" />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default">Kata Kunci</label>
            <b-form-input id="kata kunci" v-model="kata_kunci" placeholder="Kata Kunci" />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default">Pembahasan</label>
            <b-form-textarea id="textarea-default" placeholder="Pembahasan" v-model="pembahasan" rows="3" />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default">Referensi</label>
            <b-form-textarea id="textarea-default" placeholder="Referensi" v-model="referensi" rows="3" />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"><b>Upload Video</b></label>
            <b-form-group>
              <b-form-file v-if="uploadReady" ref="file" type="file" class="form-control" name="video"
                v-on:change="videoUpload($event.target)" />
            </b-form-group>
            <b-progress v-if="isLoading" animated :value="progressBar" :style="{ width: progressBar + '%' }" show-value
              :class="'progress-bar-info'" />
          </b-col>
        </b-row>
        <b-col md="6" xl="1" class="mb-1">
          <b-form-input hidden id="basicInput" v-model="hidden_video" placeholder="Name Video" />
        </b-col>
        <b-row>
          <b-col md="6" xl="4" class="mb-1" />
          <b-col md="6" xl="2" class="mb-1">
            <b-button variant="success" style="width: 100%" type="submit">
              <feather-icon icon="SaveIcon" style="color: white" />
              <span class="align-middle"><b style="color: white"> Simpan</b></span>
            </b-button>
          </b-col>
          <b-col md="6" xl="2" class="mb-1">
            <b-button variant="danger" style="width: 100%" type="button" @click="cancelButton('info')">
              <feather-icon icon="XCircleIcon" style="color: white" />
              <span class="align-middle"><b style="color: white"> Batal</b></span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BCard,
  BButton,
  BForm,
  BToast,
  BCardHeader,
  BNav,
  BCardTitle,
  BFormSelect,
  BNavItem,
  BFormInput,
  BRow,
  BFormCheckbox,
  BCol,
  BFormGroup,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Swal from "sweetalert2";
export default {
  components: {
    BCard,
    BCardTitle,
    BTableSimple,
    BThead,
    BTr,
    BForm,
    BTh,
    BFormCheckbox,
    BToast,
    BTd,
    ToastificationContent,
    BTbody,
    Swal,
    BTfoot,
    BButton,
    BCardHeader,
    BNav,
    BFormTextarea,
    BNavItem,
    BFormInput,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    vSelect,
  },
  data() {
    return {
      id: this.$route.params.id,
      listCategories: [],
      listStudies: [],
      listTinjauan1: [],
      listTinjauan2: [],
      listTinjauan3: [],
      listTinjauan4: [],
      listTinjauan5: [],
      listTinjauan6: [],
      listTinjauan7: [],
      listTinjauan8: [],
      listTinjauan9: [],
      id_category: "",
      id_study: "",
      question: "",
      tinjauansatuses_id: 9999,
      tinjauanduas_id: 9999,
      tinjauantigas_id: 9999,
      tinjauanempats_id: 9999,
      tinjauanlimas_id: 9999,
      tinjauanenams_id: 9999,
      tinjauantujuhs_id: 9999,
      tinjauandelapans_id: 9999,
      tinjauansembilans_id: 9999,
      referensi: "",
      pembahasan: "",
      kata_kunci: "",
      video: null,
      isEdit: "Tidak",
      isLoading: false,
      progressBar: 0,
      uploadReady: true,
      files: [],
      videos: [],
      hidden_video: "",
    };
  },
  async mounted() {
    this.checkAuth();
    await this.getCategories();
    this.getStudies();
    this.getTinjauan1();
    this.getTinjauan2();
    this.getTinjauan3();
    this.getTinjauan4();
    this.getTinjauan5();
    this.getTinjauan6();
    this.getTinjauan7();
    this.getTinjauan8();
    this.getTinjauan9();
  },

  methods: {
    checkAuth() {
      var sessionCheck = localStorage.getItem("Uid");
      var levelCheck = localStorage.getItem("Ulevel");
      if (sessionCheck == null || sessionCheck == "") {
        this.$router.push({ name: "login" });
      }
      else if (levelCheck != 2) {
        this.$router.push({ name: "dashboard" });
      }
    },
    async addData() {
      //   e.preventDefault();
      const formData = new FormData();
      formData.append("id_category", this.id_category);
      formData.append("id_study", this.id_study);
      formData.append("question", this.question);
      formData.append("tinjauansatuses_id", this.tinjauansatuses_id);
      formData.append("tinjauanduas_id", this.tinjauanduas_id);
      formData.append("tinjauantigas_id", this.tinjauantigas_id);
      formData.append("tinjauanempats_id", this.tinjauanempats_id);
      formData.append("tinjauanlimas_id", this.tinjauanlimas_id);
      formData.append("tinjauanenams_id", this.tinjauanenams_id);
      formData.append("tinjauantujuhs_id", this.tinjauantujuhs_id);
      formData.append("tinjauandelapans_id", this.tinjauandelapans_id);
      formData.append("tinjauansembilans_id", this.tinjauansembilans_id);
      formData.append("pembahasan", this.pembahasan);
      formData.append("kata_kunci", this.kata_kunci);
      formData.append("referensi", this.referensi);
      formData.append("video", this.video);
      formData.append("hidden_video", this.hidden_video);
      await this.$http
        .post(process.env.VUE_APP_BACKEND_URL + "questions/create", formData, {

          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent) {
            this.progressBar = parseInt(
              Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )
            );
          }.bind(this),
        })
        .then((res) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Notification",
                icon: "CheckCircleIcon",
                text: "Berhasil Ubah Data",
                variant: "success",
              },
            },
            {
              timeout: 5000,
            },
            {
              position: "top-right",
            }
          );
          this.$router.push({ name: "list_question" });
          this.id_category = "";
          this.id_study = "";
          this.pembahasan = "";
          this.kata_kunci = "";
          this.video = "";
          this.question = "";
          this.referensi = "";
          this.hidden_video = "";
          setTimeout(() => {
            this.photoReset();
            this.videoReset();
            this.isLoading = false;
            this.progressBar = 0;
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cancelButton(variant = null) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "XCircleIcon",
            text: "Cancel Perubahan Data",
            variant: "info",
          },
        },
        {
          timeout: 5000,
        },
        {
          position: "top-right",
        }
      );
      this.$router.push({ name: "list_question" });
      setTimeout(() => {
        this.photoReset();
        this.videoReset();
        this.isLoading = false;
        this.progressBar = 0;
      }, 2000);
    },
    getCategories() {
      this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "categories/view")
        .then((res) => {
          this.listCategories = res.data.data;
        });
    },
    getStudies() {
      this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "studies/view")
        .then((res) => {
          this.listStudies = res.data.data;
        });
    },
    getTinjauan1() {
      this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "tinjauan_satu/view")
        .then((res) => {
          this.listTinjauan1 = res.data.data;
        });
    },
    getTinjauan2() {
      this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "tinjauan_dua/view")
        .then((res) => {
          this.listTinjauan2 = res.data.data;
        });
    },
    getTinjauan3() {
      this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "tinjauan_tiga/view")
        .then((res) => {
          this.listTinjauan3 = res.data.data;
        });
    },
    getTinjauan4() {
      this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "tinjauan_empat/view")
        .then((res) => {
          this.listTinjauan4 = res.data.data;
        });
    },
    getTinjauan5() {
      this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "tinjauan_lima/view")
        .then((res) => {
          this.listTinjauan5 = res.data.data;
        });
    },
    getTinjauan6() {
      this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "tinjauan_enam/view")
        .then((res) => {
          this.listTinjauan6 = res.data.data;
        });
    },
    getTinjauan7() {
      this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "tinjauan_tujuh/view")
        .then((res) => {
          this.listTinjauan7 = res.data.data;
        });
    },
    getTinjauan8() {
      this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "tinjauan_delapan/view")
        .then((res) => {
          this.listTinjauan8 = res.data.data;
        });
    },
    getTinjauan9() {
      this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "tinjauan_sembilan/view")
        .then((res) => {
          this.listTinjauan9 = res.data.data;
        });
    },
    videoUpload(event) {
      this.video = event.files[0];
    },
    videoReset() {
      this.uploadReady = false;
      this.$nextTick(() => {
        this.uploadReady = true;
      });
    },
  },
};
</script>
